import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import { AppProvider, useAppContext } from './components/AppProvider';
import { useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import { LoginClient, trackPageView } from 'common.f6st.com';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { RegistrationPage } from './pages/RegistrationPage';
import { ProductsPage } from './pages/ProductsPage';
import { BusinessSettingsPage } from './pages/BusinessSettingsPage';
import { OrdersPage } from './pages/OrderPage';
import { ProfilePage } from './pages/ProfilePage';
import { QrCodePage } from './pages/QrCodePage';
import { UsersPage } from './pages/UserPage';
import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { LoginRootPage } from './pages/LoginRootPage';

function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getLoggedInUser } = useAppContext();
  const loggedInUser = getLoggedInUser();
  const isAdmin = loggedInUser && loggedInUser.admin;

  useEffect(() => {
    if (!loggedInUser) {
      if (location.pathname !== '/registration' && location.pathname !== '/login' && location.pathname !== '/loginRoot') {
        navigate('/login');
      }
    }
  }, [loggedInUser, navigate, location.pathname]);

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  const userRoutes = useMemo(() => (
    <>
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/" element={<HomePage />} />
    </>
  ), []);

  const adminRoutes = useMemo(() => (
    <>
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/business" element={<BusinessSettingsPage />} />
      <Route path="/qr-codes" element={<QrCodePage />} />
      <Route path="/users" element={<UsersPage />} />
    </>
  ), []);

  return (
    <Routes>
      <Route path="/loginRoot" element={<LoginRootPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      {loggedInUser && userRoutes}
      {isAdmin && adminRoutes}
    </Routes>
  );
}

export function App() {
  return (
    <AppProvider>
      <AppWithProvider />
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AppProvider>
  );
}

function AppWithProvider() {
  const { loading } = useAppContext();

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return <AppRoutes />;
}
