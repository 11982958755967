import React, { useState, useEffect } from 'react';
import { Box, Link, Checkbox, FormControlLabel, TextField, FormGroup, Typography } from '@mui/material';;
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ReceiptPdfUtil } from '../util/ReceiptPdfUtil';
import { Customer, Order } from 'base.f6st.com';

interface ReceiptLinkProps {
  order: Order;
  customer: Customer;
}

interface BusinessReceiptData {
  companyName: string;
  companyAddress: string;
  vatID: string;
}

const businessReceiptSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  companyAddress: Yup.string().required('Required'),
  vatID: Yup.string().required('Required'),
});

export const ReceiptLink: React.FC<ReceiptLinkProps> = ({ order, customer }) => {
  const { t } = useTranslation();
  const [isBusinessReceipt, setIsBusinessReceipt] = useState(false);
  const [businessReceiptData, setBusinessReceiptData] = useState<BusinessReceiptData | undefined>(undefined);

  useEffect(() => {
    const storedData = localStorage.getItem('businessReceiptData');
    if (storedData) {
      setBusinessReceiptData(JSON.parse(storedData));
    }
  }, []);

  const handleDownloadReceipt = async (values: BusinessReceiptData) => {
    if (isBusinessReceipt) {
      setBusinessReceiptData(values);
      localStorage.setItem('businessReceiptData', JSON.stringify(values));
    }

    try {
      const translatedLabels = {
        receiptNumber: t('Receipt Number'),
        date: t('Date'),
        quantity: t('QTY'),
        item: t('Item'),
        price: t('Price'),
        vat: t('VAT'),
        netTotal: t('Net Total'),
        total: t('Total'),
        tip: t('Tip'),
        serviceFee: t('Service Fee'),
        discount: t('Discount'),
        vatID: t('VAT ID'),
        phone: t('Phone'),
        deliveryFee: t('Delivery Fee'),
        laterPaymentInPerson: t('In person'),
        paymentMethod: t('Payment Method'),
        deliveryInfo: t('Delivery Information'),
        fullName: t('Full Name'),
        deliveryInstructions: t('Delivery Instructions'),
        address: t('Address'),
        customerInfo: t('Customer'),
        companyName: t('Name'),
        companyAddress: t('Address')
      };

      const pdfBytes = await ReceiptPdfUtil.generateReceiptPDF(order, customer, translatedLabels, values);
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      saveAs(blob, `Receipt_${order.id}.pdf`);
    } catch (error) {
      console.error('Error generating receipt PDF:', error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Link href="#" onClick={() => handleDownloadReceipt(businessReceiptData!)} style={{ fontSize: '0.875rem' }}>
          {t('Download')}
        </Link>
        <FormControlLabel
          control={
            <Checkbox
              checked={isBusinessReceipt}
              onChange={(e) => setIsBusinessReceipt(e.target.checked)}
              size="small"
            />
          }
          label={<Typography style={{ fontSize: '0.875rem' }}>{t('Business Receipt')}</Typography>}
        />
      </Box>

      {isBusinessReceipt && (
        <Formik
          initialValues={
            businessReceiptData || {
              companyName: '',
              companyAddress: '',
              vatID: '',
            }
          }
          validationSchema={businessReceiptSchema}
          onSubmit={(values) => handleDownloadReceipt(values)}
        >
          {({ errors, touched, values, handleSubmit }) => (
            <Form style={{ width: '100%' }} onBlur={() => handleDownloadReceipt(values)}>
              <FormGroup>
                <Field
                  name="companyName"
                  as={TextField}
                  label={t('Company Name')}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                  fullWidth
                  style={{ marginTop: '16px', marginBottom: '16px' }}
                />
                <Field
                  name="companyAddress"
                  as={TextField}
                  label={t('Company Address')}
                  error={touched.companyAddress && Boolean(errors.companyAddress)}
                  helperText={touched.companyAddress && errors.companyAddress}
                  fullWidth
                  style={{ marginBottom: '16px' }}
                />
                <Field
                  name="vatID"
                  as={TextField}
                  label={t('VAT ID')}
                  error={touched.vatID && Boolean(errors.vatID)}
                  helperText={touched.vatID && errors.vatID}
                  fullWidth
                  style={{ marginBottom: '16px' }}
                />
              </FormGroup>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
