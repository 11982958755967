export type Address = {
    placeId: string; // Google Places id of the address
    zip: string;
    city: string;
    street: string;
    lat: number;
    lng: number;
};

export type AddressLocation = {
  latitude: number;
  longitude: number;
};

export type Products = {
  categories: ProductCategory[];
  filters: ProductFilter[];
  productsTextsVersion: string; // products text version for the primary language, lower versions will be re-translated 
};

export type ProductsTexts = {
  version: string;// unique version of the product, changed after every update  
  [key: string]: string; // text Id with values
};

export type Customer = {
  id: string;
  active: boolean;
  businessSettings: BusinessSettings;
  orderSettings: OrderSettings;
  products: Products;
  [key: string]: ProductsTexts | any; // all the product texts are stored in lang specific tables products_en
};

export type OrderSettings = {
  orderingOpen: boolean; // the whole ordering process can be disabled
  categoryIdsBlocked?: string[]; // categories cannot be ordered
  productIdsBlocked?: string[]; // products cannot be ordered
};

export type Product = {
  id: string;
  nameId: string;
  price?: number; // either one price or multiple sizes with prices
  sizes?: Size[];
  descriptionId: string;
  imgId?: string;
  filterIds?: string[];
  extras: ProductExtra[];
};

export type ProductCategory = {
  id: string;
  nameId: string;
  products: Product[];
  categoryVAT?: number; // optional VAT percentage for all products in the category instead of the default value in the business settings 
};

export type Size = {
  id: string;
  price: number;
  nameId: string;
};

export type ProductExtra = {
  id: string;
  nameId: string;
  price: number;
};

export type ProductFilter = {
  id: string;
  nameId: string;
};

export type Team = {
  id: string;
  name: string; // team name
  categoryIds: string[]; // team is responsible for the following product categories
};

export type DeliverySettings = {
  maxDistance: number; // maximum distance to deliver wihtin the given country of the buinsess
  maxDistanceAddress: Address; // address calcualted for max distance in km/miles (depending on the metrics system)
  excludedZips?: string[]; // optionoally zip codes excluded from delivery (within the distance)
  minimumOrderAmount?: number; // the minimum cost for making an order for delivery, when undefined then no minimum value
  freeDeliveryAmountLimit?: number; // free delivery is provided from this amount limit, when undefined then no free delivery limit 
  deliveryFee?: number; // delivery fee (if no free delivery), when undefined then no delivery fee, fix cost 
};

export type BusinessSettings = {
  businessName: string; // name of the business in the main business language
  countryCode: string; // ISO country code of the business
  languageCodePrimary: string; // main language for the business e.g. receipt
  address: Address; // business address
  connectedAccountId: string; // Stripe connected account id
  testMode?: boolean; // test mode, no real payment
  teams?: Team[]; // teams to handle orders, when undefined then this feature is not supproted
  serviceFeePercentage?: number; // additonal charge for service cost, when undefined then this feature is not supproted
  discountPercentage?: number; // percentage discount for returning customers, when undefined then this feature is not supproted
  tipsPercentage?: number; // customer can add certain % tips to their paymentt, when undefined value then this feature is not supproted
  payInPerson?: boolean; // pay in person enabled e.g. cash, terminal  
  delivery?: DeliverySettings; // it is possible to use QR codes where any address can be set, when undefined then this feature is not supproted 
  orderEmailNotification?: boolean; // email notification for new orders
  productRecommendation?: boolean; // product recommendation via ChatGPT
  defaultVAT: number; // default Value-added tax percentage for products
  vatID: string; // VAT id for receipts
  phoneNumber: string; // Phone numbers shown on receipts
};

export enum CustomerUpdateAttributes {
  BUSINESSSETTINGS = 'businessSettings',
  CONTACTINFO = 'contactInfo',
  ORDERSETTINGS = 'orderSettings',
  ACTIVE = 'active',
  PRODUCTS = 'products',
}
