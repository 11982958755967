import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Chip,
    Autocomplete
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../AppProvider';
import { XLSUtil } from '../../util/XLSUtil';
import { ProductsTextsUtil, ProductCategory, Product, CustomerUpdateAttributes, CallerType } from 'base.f6st.com';
import { StandardSwitch, SecondaryButton, StandardButton, CustomerClient } from 'common.f6st.com';

type OrderSettingsDialogProps = {
    open: boolean;
    onClose: () => void;
};

export const OrderSettingsDialog: React.FC<OrderSettingsDialogProps> = ({ open, onClose }) => {
    const { t } = useTranslation();

    const context = useAppContext();
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }

    const { orders } = context;
    const customer = useAppContext().getCustomer();
    const [orderOpen, setOrderOpen] = useState(customer.orderSettings.orderingOpen);
    const [blockedCategories, setBlockedCategories] = useState<string[]>(customer.orderSettings.categoryIdsBlocked || []);
    const [blockedProducts, setBlockedProducts] = useState<string[]>(customer.orderSettings.productIdsBlocked || []);
    const products = customer.products;
    const texts = new ProductsTextsUtil(customer);

    useEffect(() => {
        setOrderOpen(customer.orderSettings.orderingOpen);
        setBlockedCategories(customer.orderSettings.categoryIdsBlocked || []);
        setBlockedProducts(customer.orderSettings.productIdsBlocked || []);
    }, [customer.orderSettings]);

    const handleOrderToggle = () => {
        setOrderOpen(!orderOpen);
    };

    const handleBlockedCategoriesChange = (_: React.ChangeEvent<{}>, newValue: string[]) => {
        setBlockedCategories(newValue);
    };

    const handleBlockedProductsChange = (_: React.ChangeEvent<{}>, newValue: string[]) => {
        setBlockedProducts(newValue);
    };

    const handleDownloadOrders = () => {
        XLSUtil.downloadOrders(orders, texts);
    };

    const handleSave = async () => {
        const updatedOrderSettings = {
            orderingOpen: orderOpen,
            categoryIdsBlocked: blockedCategories,
            productIdsBlocked: blockedProducts
        };

        const hasChanges = JSON.stringify(customer.orderSettings) !== JSON.stringify(updatedOrderSettings);

        if (hasChanges) {
            await CustomerClient.updateCustomer(CallerType.ADMIN, customer.id, [
                { attribute: CustomerUpdateAttributes.ORDERSETTINGS, value: updatedOrderSettings }
            ]);

            customer.orderSettings = updatedOrderSettings;

            toast.success(t('Order settings updated successfully'));
        }

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{t('Order Settings')}</Typography>
                    <IconButton edge="end" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', width: '30%' }}>{t('Ordering Open')}</TableCell>
                            <TableCell style={{ width: '70%' }}>
                                <StandardSwitch
                                    checked={orderOpen}
                                    onChange={handleOrderToggle}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', width: '30%' }}>{t('Blocked to order')}</TableCell>
                            <TableCell style={{ width: '70%' }}>
                                <Box mb={2}>
                                    <Autocomplete
                                        multiple
                                        disableClearable
                                        options={products.categories.map((category: ProductCategory) => category.id)}
                                        value={blockedCategories}
                                        onChange={(_, newValue: string[]) => handleBlockedCategoriesChange(_, newValue)}
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: string, index: number) => {
                                                const { key, ...tagProps } = getTagProps({ index });
                                                const categoryName = products.categories.find((category: ProductCategory) => category.id === option)?.nameId;
                                                const displayName = categoryName ? texts.get(categoryName) : option;
                                                return (
                                                    <Chip
                                                        key={option}
                                                        variant="outlined"
                                                        label={displayName}
                                                        {...tagProps}
                                                    />
                                                );
                                            })
                                        }
                                        getOptionLabel={(option: string) => {
                                            const category = products.categories.find((category: ProductCategory) => category.id === option);
                                            return category ? texts.get(category.nameId) : option;
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Product Categories"
                                                placeholder="Select categories"
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) => option === value}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <Autocomplete
                                        multiple
                                        disableClearable
                                        options={products.categories.flatMap((category: ProductCategory) => category.products.map((product: Product) => product.id))}
                                        value={blockedProducts}
                                        onChange={(_, newValue: string[]) => handleBlockedProductsChange(_, newValue)}
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: string, index: number) => {
                                                const { key, ...tagProps } = getTagProps({ index });
                                                const productName = products.categories.flatMap((category: ProductCategory) => category.products).find((product: Product) => product.id === option)?.nameId;
                                                const displayName = productName ? texts.get(productName) : option;
                                                return (
                                                    <Chip
                                                        key={option}
                                                        variant="outlined"
                                                        label={displayName}
                                                        {...tagProps}
                                                    />
                                                );
                                            })
                                        }
                                        getOptionLabel={(option: string) => {
                                            const product = products.categories.flatMap((category: ProductCategory) => category.products).find((product: Product) => product.id === option);
                                            return product ? texts.get(product.nameId) : option;
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Products"
                                                placeholder="Select products"
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) => option === value}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', width: '30%' }}>{t('Download Orders')}</TableCell>
                            <TableCell style={{ width: '70%' }}>
                                <Link href="#" onClick={handleDownloadOrders}>
                                    {t('Download')}
                                </Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
                    <SecondaryButton text="Cancel" action={onClose} />
                    <StandardButton text="Save" action={handleSave} />
                </Box>
            </DialogActions>
        </Dialog>
    );
};
