import { useState, useEffect, useRef } from "react";
import {
    Paper,
    Container,
    Stack,
    Typography,
} from "@mui/material";
import { useAppContext } from "../components/AppProvider";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { DraggableList } from "../components/DraggableList";
import { CategoryDetails } from "../components/CategoryDetails";
import { CustomAppBar } from "../components/CustomAppBar";
import { ProductDetails } from "../components/ProductDetails";
import { ProductCategory, Product, ProductsTextsUtil, IDUtil, CallerType } from "base.f6st.com";
import { S3Client, CustomerClient, StandardButton } from "common.f6st.com";

type LastSelectionType = 'category' | 'product' | '';

export const ProductsPage = () => {
    const { getCustomer, loadCustomer } = useAppContext();
    const customer = getCustomer();
    const [categories, setCategories] = useState<ProductCategory[]>([]);
    const [currentCategory, setCurrentCategory] = useState<ProductCategory | null>(null);
    const [currentProduct, setCurrentProduct] = useState<Product | null>(null);
    const [lastSelection, setLastSelection] = useState<LastSelectionType>('');
    const [texts, setTexts] = useState<ProductsTextsUtil | null>(null);
    const navigate = useNavigate();
    const fetchInitiated = useRef(false);

    useEffect(() => {
        const fetchProducts = async () => {
            const customerNew = await loadCustomer(customer.id, customer.businessSettings.languageCodePrimary);
            const texts = new ProductsTextsUtil(customerNew);
            const products = customerNew.products;
            const copiedCategories = products.categories.map((category: ProductCategory) => ({ ...category }));
            setCategories(copiedCategories);
            setTexts(texts);
        };

        if (!fetchInitiated.current) {
            fetchProducts();
            fetchInitiated.current = true;
        }
    }, [customer, loadCustomer]);

    const handleCategoryChange = (category: ProductCategory) => {
        if (currentCategory?.id !== category.id) {
            setCurrentCategory(category);
            setCurrentProduct(null);
            setLastSelection('category');
        }
    };

    const handleProductChange = (product: Product) => {
        setCurrentProduct(product);
        setLastSelection('product');
    };

    const handleAddCategory = () => {
        const newCategory: ProductCategory = {
            id: IDUtil.getShortId(),
            nameId: "",
            products: [],
        };
        setCategories([...categories, newCategory]);
        handleCategoryChange(newCategory);
    };

    const handleAddProduct = () => {
        if (currentCategory) {
            const newProduct: Product = {
                id: IDUtil.getShortId(),
                nameId: "",
                descriptionId: "",
                sizes: [],
                imgId: "",
                filterIds: [],
                extras: []
            };
            const updatedCategory: ProductCategory = {
                ...currentCategory,
                products: [...currentCategory.products, newProduct],
            };
            setCategories(
                categories.map((category) =>
                    category.id === currentCategory.id ? updatedCategory : category
                )
            );
            handleProductChange(newProduct);
            setCurrentCategory(updatedCategory);
        }
    };

    const handleDeleteCategory = (category: ProductCategory) => {
        setCategories(categories.filter(cat => cat.id !== category.id));
        setCurrentCategory(null);
        setCurrentProduct(null);
        setLastSelection('');
    };

    const handleDeleteProduct = (product: Product) => {
        if (currentCategory) {
            const updatedCategory: ProductCategory = {
                ...currentCategory,
                products: currentCategory.products.filter(prod => prod.id !== product.id),
            };
            setCategories(
                categories.map((category) =>
                    category.id === currentCategory.id ? updatedCategory : category
                )
            );
            setCurrentCategory(updatedCategory);
            setCurrentProduct(null);
            setLastSelection('category');
        }
    };

    const handleSave = async () => {
        if (!texts) return;

        const products = customer.products;

        // Collect all image IDs from the products and filter out undefined values
        const imageIdsToKeep: string[] = categories.flatMap(category =>
            category.products.map(product => product.imgId).filter((imgId): imgId is string => imgId !== undefined)
        );

        products.categories = categories;

        // Run cleanup as an async method
        await S3Client.cleanup(CallerType.ADMIN, customer.id, imageIdsToKeep);
        await CustomerClient.updateProductsPrimary(CallerType.ADMIN, customer.id, products, texts.allProductsTexts, customer.businessSettings.languageCodePrimary);
        toast.success('Products saved successfully');
        navigate('/');
    };

    const handleCategoryDetailsChange = (updatedCategory: ProductCategory) => {
        const updatedCategories = categories.map((category) =>
            category.id === updatedCategory.id ? updatedCategory : category
        );
        setCategories(updatedCategories);
        setCurrentCategory(updatedCategory);
    };

    const handleProductDetailsChange = (updatedProduct: Product) => {
        if (currentCategory) {
            const updatedCategory = {
                ...currentCategory,
                products: currentCategory.products.map((prod) =>
                    prod.id === updatedProduct.id ? updatedProduct : prod
                ),
            };
            setCategories(
                categories.map((category) =>
                    category.id === currentCategory.id ? updatedCategory : category
                )
            );
            setCurrentCategory(updatedCategory);
            setCurrentProduct(updatedProduct);
        }
    };

    if (!texts) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <>
            <CustomAppBar backButton pageTitle="Products" />
            <Container>
                <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
                    <Stack direction="row" spacing={2}>
                        <DraggableList
                            items={categories.map(category => ({
                                ...category,
                                name: texts.get(category.nameId),
                            }))}
                            title="Categories"
                            onItemSelect={handleCategoryChange}
                            onItemDelete={handleDeleteCategory}
                            onAddItem={handleAddCategory}
                            onDragEnd={(updatedItems: ProductCategory[]) => setCategories(updatedItems)}
                        />
                        {currentCategory && (
                            <DraggableList
                                items={currentCategory.products.map(product => ({
                                    ...product,
                                    name: texts.get(product.nameId),
                                }))}
                                title="Products"
                                onItemSelect={handleProductChange}
                                onItemDelete={handleDeleteProduct}
                                onAddItem={handleAddProduct}
                                onDragEnd={(updatedItems: Product[]) => {
                                    const updatedCategory = {
                                        ...currentCategory,
                                        products: updatedItems,
                                    };
                                    setCategories(
                                        categories.map((category) =>
                                            category.id === currentCategory.id ? updatedCategory : category
                                        )
                                    );
                                    setCurrentCategory(updatedCategory);
                                }}
                            />
                        )}
                        {lastSelection === 'category' && currentCategory && (
                            <CategoryDetails
                                category={currentCategory}
                                onSave={handleSave}
                                onCategoryChange={handleCategoryDetailsChange}
                            />
                        )}
                        {lastSelection === 'product' && currentProduct && (
                            <ProductDetails
                                product={currentProduct}
                                onSave={handleSave}
                                onProductChange={handleProductDetailsChange}
                            />
                        )}
                    </Stack>

                    <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
                        {categories.length > 0 && (
                            <>
                                <StandardButton text="Save" action={handleSave} />
                            </>
                        )}
                    </Stack>
                </Paper>
            </Container>
        </>
    );
};
