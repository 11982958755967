import { ProductsTexts, Customer, Products } from "../types/CustomerTypes.js";
import { Log } from "./Log.js";
import { ProductUtil } from "./ProductUtil.js";

export class ProductsTextsUtil {
    private productsTexts: ProductsTexts;
    private languageCode: string;
  
    constructor(customer: Customer, languageCode?: string) {
      this.languageCode = languageCode || customer.businessSettings.languageCodePrimary;
      this.productsTexts = ProductUtil.getProductsTexts(customer, this.languageCode);
    }
  
    public get(textId: string | undefined): string {
      if (!textId || !this.productsTexts[textId]) {
        Log.warn("No text id or text not found", { textId, languageCode: this.languageCode, productsTexts: this.productsTexts });
        return '<Unknown>';
      }
      return this.productsTexts[textId].trim();
    }
  
    public set(textId: string, text: string): void {
      if (!textId) {
        Log.warn("No text id provided", { textId, languageCode: this.languageCode, text });
        return;
      }
      this.productsTexts[textId] = text.trim();
    }
  
    public get allProductsTexts(): ProductsTexts {
      return this.productsTexts;
    }


    // remove redundant texts from productsTexts and update products to use the optimized text IDs
    public static optimizeTexts(products: Products, productsTexts: ProductsTexts): ProductsTexts {
      const textToIdMap: { [text: string]: string } = {};
      const optimizedProductsTexts: ProductsTexts = { version: productsTexts.version };
  
      // Create a mapping of text values to unique IDs and optimize productsTexts
      for (const [textId, textValue] of Object.entries(productsTexts)) {
        if (textId === 'version') continue;
  
        // Explicitly assert that textValue is a string
        const value = textValue as string;
        if (textToIdMap[value]) {
          optimizedProductsTexts[textId] = textToIdMap[value];
          Log.debug(`Redundant text optimized: '${value}' using ID '${textToIdMap[value]}'`);
        } else {
          textToIdMap[value] = textId;
          optimizedProductsTexts[textId] = value;
        }
      }
  
      // Update the products to use the optimized text IDs
      const idMap = this.createIdMap(textToIdMap, optimizedProductsTexts);
      this.updateProductsTextReferences(products, idMap);
  
      return optimizedProductsTexts;
    }

    private static createIdMap(textToIdMap: { [text: string]: string }, productsTexts: ProductsTexts): { [oldId: string]: string } {
      const idMap: { [oldId: string]: string } = {};
  
      for (const [textId, textValue] of Object.entries(productsTexts)) {
        const value = textValue as string;
        if (textId === 'version') continue;
        const canonicalId = textToIdMap[value];
        if (canonicalId && canonicalId !== textId) {
          idMap[textId] = canonicalId;
        }
      }
  
      return idMap;
    }
  
    private static updateProductsTextReferences(products: Products, idMap: { [oldId: string]: string }): void {
      products.categories.forEach(category => {
        category.products.forEach(product => {
          product.nameId = idMap[product.nameId] || product.nameId;
          product.descriptionId = idMap[product.descriptionId] || product.descriptionId;
          product.sizes?.forEach(size => {
            size.nameId = idMap[size.nameId] || size.nameId;
          });
          product.extras.forEach(extra => {
            extra.nameId = idMap[extra.nameId] || extra.nameId;
          });
        });
      });
    }
    
  }
  