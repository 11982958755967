import { Customer, Product, ProductCategory, ProductExtra, Size } from "../types/CustomerTypes.js";
import { OrderItem, Order } from "../types/OrderTypes.js";
import { AppError } from "./AppError.js";
import { ProductsTextsUtil } from "./ProductsTextsUtil.js";

export class OrderUtil {

    public static toStringForOrderNotification(order: Order, txt: ProductsTextsUtil): string {
        const orderItemsStr = order.items.map(item => {
            const productName = txt.get(item.product.nameId);
            const productDescription = txt.get(item.product.descriptionId);
            return `${productName} (${productDescription}) x ${item.quantity}`;
        }).join(', ');

        const totalString = OrderUtil.getOrderAmount(order).totalString;

        return `
          Order ID: ${order.id}
          Order Date: ${order.date.toISOString()}
          Order Status: ${order.orderStatus}
          Payment Status: ${order.paymentStatus}
          Items: ${orderItemsStr}
          Total Amount: ${order.currency} ${totalString}
          Tips: ${order.tipPercentage || 0}%
          Comeback Discount: ${order.discountPercentage || 0}%
          Priority: ${order.priority ? 'Yes' : 'No'}
        `;
    }

    // used for ChatGPT product recommendation
    public static toStringForProductRecommendation(
        items: OrderItem[] | Order[] | ProductCategory[], txt: ProductsTextsUtil,
    ): string {
        if (items.length === 0) return '';

        if ('product' in items[0]) { // OrderItem[] e.g. basket
            return (items as OrderItem[]).map(item => {
                const productName = txt.get(item.product.nameId);
                const productDescription = txt.get(item.product.descriptionId);
                return `${productName} (${productDescription}) x ${item.quantity}`;
            }).join(', ');
        }

        if ('customerId' in items[0]) { // Order[] e.g. past order history
            return (items as Order[]).map(order => {
                const orderItems = order.items.map(item => {
                    const productName = txt.get(item.product.nameId);
                    const productDescription = txt.get(item.product.descriptionId);
                    return `${productName} (${productDescription}) x ${item.quantity}`;
                }).join(', ');
                return `Order: ${orderItems}`;
            }).join('; ');
        }

        if ('products' in items[0]) { // ProductCategory[] e.g. existing products
            return (items as ProductCategory[]).map(category => {
                const categoryName = txt.get(category.nameId);
                const products = category.products.map(product => {
                    const productName = txt.get(product.nameId);
                    const productDescription = txt.get(product.descriptionId);
                    const filterIds = product.filterIds && product.filterIds.length > 0 ? `filterIds=[${product.filterIds.join(', ')}]` : '';
                    return `${productName} (${productDescription}) (id=${product.id}) ${filterIds}`;
                }).join(', ');
                return `Product category ${categoryName} with products: ${products}`;
            }).join('; ');
        }

        return '';
    }

    public static getOrderAmount(order: Order, currency?: string): {
        total: number, net: number, vat: number, tip: number, discount: number, serviceFee: number, deliveryFee: number,
        totalString: string, netString: string, vatString: string, tipString: string, discountString: string, serviceFeeString: string, deliveryFeeString: string
    } {
        let net = 0;
        let vatC = 0;

        const amountCurrency = currency ? currency : order.currency;
        if (!amountCurrency || amountCurrency.length === 0) throw new AppError("Currency is required");

        // Calculate net cost and VAT cost for each order item
        order.items.forEach(item => {
            const itemTotal = this.getOrderItemTotalAmount(item, amountCurrency);
            const itemTotalAmount = itemTotal.amount;
            const itemVatAmount = Math.round((itemTotalAmount * item.vatPercentage) / (100 + item.vatPercentage) * 100) / 100; // Calculate the VAT amount from the total cost
            const itemNetAmount = itemTotalAmount - itemVatAmount; // Calculate the net amount by subtracting VAT from total

            net += itemNetAmount; // Sum of net amounts
            vatC += itemVatAmount; // Sum of VAT amounts
        });

        // Calculate discount amount if any
        let discount = 0;
        if (order.discountPercentage && order.discountPercentage > 0) {
            discount = Math.round((net * order.discountPercentage) / 100 * 100) / 100; // Calculate discount amount
            net -= discount; // Deduct discount from net cost
        }

        // Calculate total cost by adding VAT to the net cost
        let total = net + vatC;

        // Add service fee if any
        let serviceFee = 0;
        if (order.serviceFeePercentage && order.serviceFeePercentage > 0) {
            serviceFee = Math.round((net * order.serviceFeePercentage) / 100 * 100) / 100; // Calculate service fee based on net cost
            total += serviceFee; // Add service fee to total cost
        }

        // Add delivery fee if any
        let deliveryFee = 0;
        if (order.deliveryFee && order.deliveryFee > 0) {
            deliveryFee = order.deliveryFee; // Set delivery fee
            total += deliveryFee; // Add delivery fee to total cost
        }

        // Calculate tip if any
        let tip = 0;
        if (order.tipPercentage && order.tipPercentage > 0) {
            tip = Math.round(((net + vatC) * order.tipPercentage) / 100 * 100) / 100; // Calculate tip based on net and VAT
            total += tip; // Add tip to total cost
        }

        // Use formatValue helper function to format values
        const totalString = this.formatAmount(total, amountCurrency);
        const netString = this.formatAmount(net, amountCurrency);
        const vatString = this.formatAmount(vatC, amountCurrency);
        const tipString = this.formatAmount(tip, amountCurrency);
        const discountString = this.formatAmount(discount, amountCurrency);
        const serviceFeeString = this.formatAmount(serviceFee, amountCurrency);
        const deliveryFeeString = this.formatAmount(deliveryFee, amountCurrency);

        return {
            total,
            net,
            vat: vatC,
            tip,
            discount,
            serviceFee,
            deliveryFee,
            totalString,
            netString,
            vatString,
            tipString,
            discountString,
            serviceFeeString,
            deliveryFeeString
        };
    }

    public static formatAmount(value: number, currency: string): string {
        return value.toFixed(2) + ' ' + currency;
    }

    public static getOrderItemTotalAmount(item: OrderItem, currency: string): { amount: number, amountString: string } {
        let res: number = 0;
        // Get base price of the product
        const productTotal = this.getProductTotalAmount(item.product, currency, item.sizeId);
        res += productTotal.amount;
        // Add price for each extra if any
        if (item.extraIds && item.product.extras) {
            item.extraIds.forEach((extraId) => {
                const extra = item.product.extras.find((extra) => extra.id === extraId);
                if (extra) {
                    res += extra.price; // Add extra price to the result
                } else {
                    throw new AppError('Extra not found', extraId);
                }
            });
        }
        const amount = res * item.quantity;
        const amountString = this.formatAmount(amount, currency);
        return { amount, amountString };
    }

    public static getProductExtraAmount(extra: ProductExtra, currency: string): { amount: number, amountString: string } {
        return { amount: extra.price, amountString: this.formatAmount(extra.price, currency) };
    }

    public static getProductTotalAmount(product: Product, currency: string, sizeId?: string): { amount: number, amountString: string } {
        let amount: number;
        // If the product has multiple sizes, find the price of the specified size
        if (product.sizes && product.sizes.length > 1) {
            if (sizeId === undefined) {
                throw new AppError('Size id is missing for product with multiple sizes', product);
            } else {
                const size: Size | undefined = product.sizes.find((size) => size.id === sizeId);
                if (size) {
                    amount = size.price;
                } else {
                    throw new AppError('Size not found', { sizeId, product });
                }
            }
        } else {
            // Return the base price of the product if no sizes are defined
            if (product.price) {
                amount = product.price;
            } else {
                throw new AppError('No price found for product', product);
            }
        }

        const amountString = this.formatAmount(amount, currency);
        return { amount, amountString };
    }

    public static getProductVATPercentage(product: Product, customer: Customer): number {
        const category = customer.products.categories.find(cat =>
            cat.products.some(prod => prod.id === product.id));
        if (category) {
            // Return the VAT rate for the product category or the default VAT rate
            return category.categoryVAT || customer.businessSettings.defaultVAT;
        } else {
            // Return the default VAT rate if no category is found
            return customer.businessSettings.defaultVAT;
        }
    }
}
