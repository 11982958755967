import React, { useEffect, useState } from 'react';
import { Box, ListItemText, Select, MenuItem, FormControl, InputLabel, Checkbox, TextField, Autocomplete } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { useAppContext } from '../AppProvider';
import { useTranslation } from 'react-i18next';
import { ProductUtil, ProductsTextsUtil, OrderStatus } from 'base.f6st.com';

export enum OrderDate {
  LAST_1H = 'last1h',
  LAST_4H = 'last4h',
  LAST_24H = 'last24h',
  CUSTOM_DATES = 'customDates',
  EXACT_DATES = 'exactDates',
}

export type OrderFilter = {
  orderStatus: string;
  paymentStatus?: string;
  orderDate: OrderDate;
  exactDateFrom?: Date;
  exactDateTo?: Date;
  teamId?: string;
  qrCodeLocations?: string[];
  categoryId?: string;
  productId?: string;
  orderId?: string;
  qrCodeId?: string;
  buyerId?: string;
};

type OrderFilterDrawerProps = {
  toggleDrawer: (newOpen: boolean) => void;
};

export const OrderFilterDrawer: React.FC<OrderFilterDrawerProps> = ({ toggleDrawer }) => {
  const { t, i18n } = useTranslation();
  const context = useAppContext();

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  const { orderFilter, setOrderFilter, orders } = context;
  const customer = useAppContext().getCustomer();
  const [selectedOrderDate, setSelectedOrderDate] = useState<OrderDate>(OrderDate.LAST_1H);
  const [exactDateFrom, setExactDateFrom] = useState<string>(new Date().toISOString().slice(0, 10));
  const [exactDateTo, setExactDateTo] = useState<string>(new Date().toISOString().slice(0, 10));
  const [identifierType, setIdentifierType] = useState<string>('');
  const distinctQrCodeLocations = Array.from(new Set(orders.map(order => order.qrCode.locationName).filter((location): location is string => !!location)));
  const distinctCategories = Array.from(new Set(orders.flatMap(order => order.items.map(item => ProductUtil.getProductCategoryId(customer, item.product.id)).filter(Boolean))));
  const distinctProducts = Array.from(new Set(orders.flatMap(order => order.items.map(item => item.product.id)).filter(Boolean)));
  const distinctPaymentStatuses = Array.from(new Set(orders.map(order => order.paymentStatus).filter(Boolean)));
  const distinctBuyerIds = Array.from(new Set(orders.map(order => order.buyerId).filter(Boolean)));
  const distinctQrCodeIDs = Array.from(new Set(orders.map(order => order.qrCode.id).filter(Boolean)));
  const distinctOrderIds = Array.from(new Set(orders.map(order => order.id).filter(Boolean)));
  const texts = new ProductsTextsUtil(customer);
  
  useEffect(() => {
    setOrderFilter((prev: OrderFilter) => ({
      ...prev,
      orderDate: OrderDate.LAST_1H,
      exactDateFrom: undefined,
      exactDateTo: undefined,
    }));
  }, [setOrderFilter]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as OrderStatus;
    setOrderFilter((prev: OrderFilter) => ({ ...prev, orderStatus: newValue }));
  };

  const handleTeamChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value === "All" ? undefined : event.target.value;
    setOrderFilter((prev: OrderFilter) => ({ ...prev, teamId: newValue }));
  };

  const handleLocationChange = (event: SelectChangeEvent<string[]>) => {
    const newValue = event.target.value as string[];
    setOrderFilter((prev: OrderFilter) => ({ ...prev, qrCodeLocations: newValue }));
  };

  const handlePaymentStatusChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setOrderFilter((prev: OrderFilter) => ({ ...prev, paymentStatus: newValue }));
  };

  const handleOrderDateChange = (event: SelectChangeEvent<OrderDate | ''>) => {
    const newValue = event.target.value as OrderDate;
    setOrderFilter((prev: OrderFilter) => ({
      ...prev,
      orderDate: newValue,
      exactDateFrom: newValue === OrderDate.EXACT_DATES ? prev.exactDateFrom : undefined,
      exactDateTo: newValue === OrderDate.EXACT_DATES ? prev.exactDateTo : undefined,
    }));

    setSelectedOrderDate(newValue);
  };

  const handleExactDateFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setExactDateFrom(newValue);
    const fromDateValue = new Date(newValue);
    fromDateValue.setHours(0, 0, 0, 0);
    const toDateValue = exactDateTo ? new Date(exactDateTo) : fromDateValue;
    if (toDateValue < fromDateValue) {
      setExactDateTo(newValue);
      toDateValue.setHours(23, 59, 59, 999);
    }
    setOrderFilter((prev: OrderFilter) => ({
      ...prev,
      exactDateFrom: fromDateValue,
      exactDateTo: toDateValue
    }));
  };

  const handleExactDateToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setExactDateTo(newValue);
    const toDateValue = new Date(newValue);
    toDateValue.setHours(23, 59, 59, 999);
    const fromDateValue = exactDateFrom ? new Date(exactDateFrom) : new Date();
    if (toDateValue < fromDateValue) {
      setExactDateFrom(newValue);
      fromDateValue.setHours(0, 0, 0, 0);
    }
    setOrderFilter((prev: OrderFilter) => ({
      ...prev,
      exactDateFrom: fromDateValue,
      exactDateTo: toDateValue
    }));
  };

  const handleIdentifierTypeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setIdentifierType(newValue);
    setOrderFilter((prev: OrderFilter) => ({
      ...prev,
      buyerId: newValue === 'Buyer ID' ? prev.buyerId : '',
      qrCodeId: newValue === 'QR Code ID' ? prev.qrCodeId : '',
      orderId: newValue === 'Order ID' ? prev.orderId : '',
    }));
  };

  const handleIdentifierValueChange = (event: any, newValue: string | null) => {
    setOrderFilter((prev: OrderFilter) => {
      let newFilter = { ...prev, buyerId: '', qrCodeId: '', orderId: '' };
      if (identifierType === 'Buyer ID') newFilter.buyerId = newValue || '';
      if (identifierType === 'QR Code ID') newFilter.qrCodeId = newValue || '';
      if (identifierType === 'Order ID') newFilter.orderId = newValue || '';
      return newFilter;
    });
  };

  const currentLang = i18n.language;

  return (
    <Box sx={{ width: 250 }} p={2} role="presentation">
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Order Status</InputLabel>
        <Select value={orderFilter.orderStatus} onChange={handleStatusChange}>
          <MenuItem value="NEW">New</MenuItem>
          <MenuItem value="READY">Ready</MenuItem>
          <MenuItem value="DELIVERED">Delivered</MenuItem>
          <MenuItem value="CANCELED">Canceled</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Order Date</InputLabel>
        <Select value={selectedOrderDate} onChange={handleOrderDateChange}>
          <MenuItem value={OrderDate.LAST_1H}>Last 1h</MenuItem>
          <MenuItem value={OrderDate.LAST_4H}>Last 4h</MenuItem>
          <MenuItem value={OrderDate.LAST_24H}>Last 24h</MenuItem>
          <MenuItem value={OrderDate.EXACT_DATES}>Exact Dates</MenuItem>
        </Select>
      </FormControl>
      {selectedOrderDate === OrderDate.EXACT_DATES && (
        <>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="From Date"
            type="date"
            value={exactDateFrom}
            onChange={handleExactDateFromChange}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="To Date"
            type="date"
            value={exactDateTo}
            onChange={handleExactDateToChange}
            InputLabelProps={{ shrink: true }}
          />
        </>
      )}
      {customer.businessSettings.teams && customer.businessSettings.teams.length > 1 && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>My Team</InputLabel>
          <Select value={orderFilter.teamId || "All"} onChange={handleTeamChange}>
            <MenuItem value="All">All</MenuItem>
            {customer.businessSettings.teams.map(team => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {distinctQrCodeLocations.length > 1 && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Location</InputLabel>
          <Select multiple value={orderFilter.qrCodeLocations || []} onChange={handleLocationChange} renderValue={(selected) => selected.join(', ')}>
            {distinctQrCodeLocations.map((location) => (
              <MenuItem key={location} value={location}>
                <Checkbox checked={orderFilter.qrCodeLocations?.includes(location) || false} />
                <ListItemText primary={location} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {distinctCategories.length > 0 && (
        <Autocomplete
          fullWidth
          disableClearable
          sx={{ mt: 2 }}
          options={distinctCategories}
          getOptionLabel={(option) => texts.get((customer).categories.find((cat: any) => cat.id === option)?.nameId)}
          renderInput={(params) => <TextField {...params} label="Category" />}
          value={orderFilter.categoryId || undefined}
          onChange={(event, newValue) => setOrderFilter(prev => ({ ...prev, categoryId: newValue || '' }))}
        />
      )}
      {orderFilter.categoryId && distinctProducts.length > 0 && (
        <Autocomplete
          fullWidth
          disableClearable
          sx={{ mt: 2 }}
          options={distinctProducts}
          getOptionLabel={(option) => texts.get((customer).categories.flatMap((cat: any) => cat.products).find((prod: any) => prod.id === option)?.nameId)}
          renderInput={(params) => <TextField {...params} label="Product" />}
          value={orderFilter.productId || undefined}
          onChange={(event, newValue) => setOrderFilter(prev => ({ ...prev, productId: newValue || '' }))}
        />
      )}
      {distinctPaymentStatuses.length > 1 && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Payment Status</InputLabel>
          <Select value={orderFilter.paymentStatus} onChange={handlePaymentStatusChange}>
            <MenuItem value="">{`All`}</MenuItem>
            {distinctPaymentStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {(orders.length > 0 || orderFilter.buyerId || orderFilter.qrCodeId || orderFilter.orderId) && (
        <>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Identifiers</InputLabel>
            <Select value={identifierType} onChange={handleIdentifierTypeChange}>
              <MenuItem value="Buyer ID">Buyer ID</MenuItem>
              <MenuItem value="QR Code ID">QR Code ID</MenuItem>
              <MenuItem value="Order ID">Order ID</MenuItem>
            </Select>
          </FormControl>
          {identifierType && (
            <Autocomplete
              fullWidth
              disableClearable
              sx={{ mt: 2 }}
              options={
                identifierType === 'Buyer ID' ? distinctBuyerIds :
                identifierType === 'QR Code ID' ? distinctQrCodeIDs :
                distinctOrderIds
              }
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} label={`Select ${identifierType}`} />}
              value={
                identifierType === 'Buyer ID' ? orderFilter.buyerId :
                identifierType === 'QR Code ID' ? orderFilter.qrCodeId :
                orderFilter.orderId || undefined
              }
              onChange={handleIdentifierValueChange}
            />
          )}
        </>
      )}
    </Box>
  );
};
