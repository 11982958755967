import React, { useState, useEffect, useCallback, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    TextField,
    Autocomplete,
    Box,
    Stack,
    Typography,
    Divider,
    FormLabel,
    Checkbox,
    FormControlLabel
} from "@mui/material";
import { useAppContext } from './AppProvider';
import { ManageFiltersDialog, SizesDialog, AddExtraDialog } from "./dialogs/ProductDetailsDialogs";
import { toast } from 'react-toastify';
import { ImageResizerDialog } from "./dialogs/ImageResizerDialog";
import { Product, Customer, ProductsTextsUtil, IDUtil, ProductExtra, Size, ProductFilter, CallerType, I18nUtil, Log, FileEncoderUtil } from "base.f6st.com";
import { S3Client, Config, SecondaryButton } from "common.f6st.com";

type ProductDetailsProps = {
    product: Product;
    onSave: () => void;
    onProductChange: (updatedProduct: Product) => void;
};

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required').max(300, 'Description cannot exceed 300 characters'),
    singlePrice: yup.number().when('multipleSizes', {
        is: false,
        then: (schema) => schema.required('Price is required').positive('Price must be positive'),
        otherwise: (schema) => schema.nullable(),
    }),
    multipleSizes: yup.boolean(),
});

export const ProductDetails = ({
    product,
    onProductChange,
}: ProductDetailsProps) => {
    const customer = useAppContext().getCustomer() as Customer;
    const currencyCode = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
    const texts = new ProductsTextsUtil(customer);
    const [selectedProduct, setSelectedProduct] = useState<Product>(product);
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [managePricesAndSizesDialogOpen, setManagePricesAndSizesDialogOpen] = useState(false);
    const [manageExtrasDialogOpen, setManageExtrasDialogOpen] = useState(false);
    const [multipleSizes, setMultipleSizes] = useState(product.sizes && product.sizes.length > 0);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const formik = useFormik({
        initialValues: {
            name: texts.get(product.nameId),
            description: texts.get(product.descriptionId),
            imgId: product.imgId ?? "",
            singlePrice: product.price?.toString() ?? "",
            multipleSizes: product.sizes && product.sizes.length > 0,
        },
        validationSchema,
        onSubmit: (values) => {
            const nameId = product.nameId || IDUtil.getShortId();
            const descriptionId = product.descriptionId || IDUtil.getShortId();

            texts.set(nameId, values.name);
            texts.set(descriptionId, values.description);

            const updatedProduct: Product = {
                ...selectedProduct,
                nameId,
                descriptionId,
                imgId: values.imgId,
                price: multipleSizes ? undefined : parseFloat(values.singlePrice),
                sizes: multipleSizes ? selectedProduct.sizes : undefined,
            };
            setSelectedProduct(updatedProduct);
            onProductChange(updatedProduct);
        },
        validateOnChange: true,
        validateOnBlur: true,
    });

    const deepEqual = (obj1: any, obj2: any) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    useEffect(() => {
        // Only update selectedProduct if the product actually changed
        if (!deepEqual(product, selectedProduct)) {
            // Update selectedProduct state
            setSelectedProduct(product);

            // Update formik values
            formik.setValues({
                name: texts.get(product.nameId),
                description: texts.get(product.descriptionId),
                imgId: product.imgId ?? "",
                singlePrice: product.price?.toString() ?? "",
                multipleSizes: product.sizes && product.sizes.length > 0,
            });
        }
    }, [product, texts, selectedProduct]);

    useEffect(() => {
        if (!texts.get(product.nameId)) {
            nameInputRef.current?.focus();
        }
    }, [product.nameId, texts]);

    useEffect(() => {
        if (formik.dirty) {
            formik.handleSubmit();
        }
    }, [formik.values]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = async () => {  // Note the async keyword here
                if (img.width < 600 || img.height < 600) {
                    toast.error("Image is too small. Minimum dimensions are 600x600 pixels.");
                    return;
                }

                if (img.width === 600 && img.height === 600) {
                    const imgId = await S3Client.uploadImage(CallerType.ADMIN, file, customer.id);
                    handleImageSave(imgId)
                } else {
                    const objectUrl = URL.createObjectURL(file); 
                    setImageSrc(objectUrl);
                    setIsCropModalOpen(true);
                }
            };
        }
    };

    const handleImageClick = () => {
        fileInputRef.current?.click();
    };

    const handleImageSave = useCallback(async (imgId: string) => {
        const updatedProduct = { ...selectedProduct, imgId: imgId };
        setSelectedProduct(updatedProduct);
        formik.setFieldValue('imgId', imgId);
        onProductChange(updatedProduct);
        setIsCropModalOpen(false);
        fileInputRef.current!.value = ''; // Reset file input value
        toast.success("Image has been successfully changed");
    }, [selectedProduct, formik, onProductChange]);

    const handleMultipleSizesChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        if (checked !== multipleSizes) {
            setMultipleSizes(checked);
            formik.setFieldValue('multipleSizes', checked);
            const updatedProduct = { ...selectedProduct, sizes: checked ? selectedProduct.sizes ?? [] : undefined };
            setSelectedProduct(updatedProduct);
            onProductChange(updatedProduct);
        }
    }, [multipleSizes, selectedProduct, formik, onProductChange]);

    const handleValueChange = (updateFn: (product: Product) => Product) => {
        const updatedProduct = updateFn(selectedProduct);
        setSelectedProduct(updatedProduct);
        onProductChange(updatedProduct);
    };

    const getOptionLabelForExtras = (option: ProductExtra) => {
        return texts.get(option.nameId) ?? "";
    };

    return (
        <div>
            <Box ref={componentRef} sx={{ p: 3 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Typography variant="h6">Product Details</Typography>
                    <Divider sx={{ my: 2 }} />
                    <FormLabel component="legend" sx={{ mt: 2 }}>Product Image (600x600 pixels)</FormLabel>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1, cursor: 'pointer', position: 'relative', width: '300px', height: '300px' }} onClick={handleImageClick}>
                        <img src={Config.getImageUrl(customer.id, selectedProduct.imgId)} alt="Product" style={{ width: "100%", height: "100%", minWidth: '300px', minHeight: '300px', objectFit: 'contain' }} />
                        <Typography variant="body2" sx={{ position: 'absolute', textAlign: 'center', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold', color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', padding: '5px' }}>
                            Click here to upload
                        </Typography>
                    </Box>
                    <input
                        accept=".jpg,.jpeg,.png,.webp"
                        style={{ display: 'none' }}
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                    />
                    <br />
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        inputRef={nameInputRef}
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={multipleSizes}
                                onChange={handleMultipleSizesChange}
                                color="primary"
                                id="multipleSizes"
                                name="multipleSizes"
                            />
                        }
                        label="Multiple sizes"
                    />
                    {multipleSizes ? (
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                            <Autocomplete
                                multiple
                                fullWidth
                                disableClearable
                                options={selectedProduct.sizes ?? []}
                                getOptionLabel={(option: Size) => `${texts.get(option.nameId)} (${option.price} ${currencyCode})`}
                                value={selectedProduct.sizes ?? []}
                                onChange={(e, newValue) => handleValueChange((p) => ({
                                    ...p,
                                    sizes: newValue,
                                }))}
                                renderInput={(params) => <TextField {...params} label="Sizes" variant="outlined" margin="normal" fullWidth />}
                            />
                            <SecondaryButton text="Add" action={() => setManagePricesAndSizesDialogOpen(true)} style={{ width: '100px' }} />
                        </Stack>
                    ) : (
                        <TextField
                            label={`Price (${currencyCode})`}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            id="singlePrice"
                            name="singlePrice"
                            value={formik.values.singlePrice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.singlePrice && Boolean(formik.errors.singlePrice)}
                            helperText={formik.touched.singlePrice && formik.errors.singlePrice}
                            type="number"
                            inputProps={{ min: 0, step: "any" }}
                        />
                    )}
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                        <Autocomplete
                            multiple
                            disableClearable
                            fullWidth
                            options={selectedProduct.extras}
                            getOptionLabel={getOptionLabelForExtras}
                            value={selectedProduct.extras}
                            onChange={(e, newValue) => handleValueChange((p) => ({
                                ...p,
                                extras: newValue,
                            }))}
                            renderInput={(params) => <TextField {...params} label="Extras" variant="outlined" margin="normal" fullWidth />}
                        />
                        <SecondaryButton text="Add" action={() => setManageExtrasDialogOpen(true)} style={{ width: '100px' }} />
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                        <Autocomplete
                            multiple
                            disableClearable
                            fullWidth
                            options={customer.products.filters || []}
                            getOptionLabel={(option: ProductFilter) => texts.get(option.nameId)}
                            value={selectedProduct.filterIds?.map((id) => customer.products.filters.find((filter: ProductFilter) => filter.id === id) as ProductFilter)}
                            onChange={(e, newValue) => handleValueChange((p) => ({
                                ...p,
                                filterIds: newValue.map((filter: ProductFilter) => filter ? filter.id : ""),
                            }))}
                            renderInput={(params) => <TextField {...params} label="Searchable Filters" variant="outlined" margin="normal" fullWidth />}
                        />
                        <SecondaryButton text="Add" action={() => setFilterDialogOpen(true)} style={{ width: '100px' }} />
                    </Stack>
                    <ManageFiltersDialog
                        open={filterDialogOpen}
                        onClose={() => setFilterDialogOpen(false)}
                        product={selectedProduct}
                        onSave={(updatedProduct) => handleValueChange(() => updatedProduct)}
                    />
                    <SizesDialog
                        open={managePricesAndSizesDialogOpen}
                        onClose={() => setManagePricesAndSizesDialogOpen(false)}
                        product={selectedProduct}
                        onSave={(updatedProduct) => handleValueChange(() => updatedProduct)}
                    />
                    <AddExtraDialog
                        open={manageExtrasDialogOpen}
                        onClose={() => setManageExtrasDialogOpen(false)}
                        product={selectedProduct}
                        onSave={(updatedProduct) => handleValueChange(() => updatedProduct)}
                    />
                    <ImageResizerDialog
                        open={isCropModalOpen}
                        onClose={() => setIsCropModalOpen(false)}
                        imageSrc={imageSrc}
                        onSave={handleImageSave}
                        customerId={customer.id}
                        imgId={selectedProduct.imgId ?? ""}
                        key={isCropModalOpen ? "open" : "closed"}
                    />
                </form>
            </Box>
        </div>
    );
};
