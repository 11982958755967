import { useEffect, useRef } from "react";
import { TextField, Box, Typography, Divider } from "@mui/material";
import { useAppContext } from "./AppProvider";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ProductCategory, ProductsTextsUtil, IDUtil } from "base.f6st.com";

type CategoryDetailsProps = {
    category: ProductCategory;
    onSave: () => void;
    onCategoryChange: (updatedCategory: ProductCategory) => void;
};

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    categoryVAT: yup.number()
        .required('VAT is required')
        .typeError('Invalid VAT format')
        .min(0, 'VAT must be greater than or equal to 0'),
});

export const CategoryDetails = ({
    category,
    onCategoryChange,
}: CategoryDetailsProps) => {
    const customer = useAppContext().getCustomer();
    const txt = new ProductsTextsUtil(customer);

    const formik = useFormik({
        initialValues: {
            name: txt.get(category.nameId) || "",
            categoryVAT: category.categoryVAT ?? customer.businessSettings.defaultVAT,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (data) => {
            let nameId = category.nameId;

            if (!nameId) {
                nameId = IDUtil.getShortId();
            } else {
                const isNameChanged = txt.get(nameId) !== data.name;
                const isVATChanged = category.categoryVAT !== data.categoryVAT;

                if (!isNameChanged && !isVATChanged) {
                    return;
                }
            }
            txt.set(nameId, data.name);

            const updatedCategory: ProductCategory = {
                ...category,
                nameId,
                categoryVAT: data.categoryVAT !== customer.businessSettings.defaultVAT ? data.categoryVAT : undefined,
            };

            onCategoryChange(updatedCategory);
        }
    });

    const componentRef = useRef<HTMLDivElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!txt.get(category.nameId)) {
            nameInputRef.current?.focus();
        }
    }, [txt, category.nameId]);

    // Automatically submit the form when values change
    useEffect(() => {
        if (formik.dirty) {
            formik.submitForm();
        }
    }, [formik.values]);

    return (
        <div ref={componentRef}>
            <Box sx={{ border: 1, borderColor: 'grey.300', p: 2, borderRadius: 1 }}>
                <Typography variant="h6" component="div">Category Details</Typography>
                <Divider sx={{ my: 1 }} />

                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        inputRef={nameInputRef}
                    />

                    <TextField
                        id="categoryVAT"
                        name="categoryVAT"
                        label="Category VAT (%)"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.categoryVAT}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.categoryVAT && Boolean(formik.errors.categoryVAT)}
                        helperText={formik.touched.categoryVAT && formik.errors.categoryVAT}
                        type="number"
                        inputProps={{ min: 0, step: "any" }}
                    />
                </form>
            </Box>
        </div>
    );
};
