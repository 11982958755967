import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import SettingsIcon from '@mui/icons-material/Settings';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import { Drawer, Dialog, DialogContent, Box } from '@mui/material';
import { LanguageSelector, SecondaryButton } from 'common.f6st.com';
import { OrderFilterDrawer } from './order/OrderFilterDrawer';
import { OrderSettingsDialog } from './order/OrderSettingsDialog';
import { useTranslation } from 'react-i18next';
import { useAppContext } from "./AppProvider";

type CustomAppBarProps = {
  backButton?: boolean;
  pageTitle: string;
  showOrderFunctions?: boolean; // Optional attribute for showing order functions
  showProfile?: boolean; // Optional attribute for showing profile icon
  refreshOrders?: () => void; // Optional function to refresh orders
};

export const CustomAppBar = ({ backButton = false, pageTitle, showOrderFunctions = false, showProfile = true, refreshOrders }: CustomAppBarProps) => {
  const navigate = useNavigate();
  const { i18n }  = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [refreshDisabled, setRefreshDisabled] = useState(false);
  const { getLoggedInUser } = useAppContext();
  let showProfileFlag = showProfile;
  if (getLoggedInUser()?.root === true) {
    showProfileFlag = false
  } 

  const toggleDrawer = (newOpen: boolean) => {
    setDrawerOpen(newOpen);
  };

  const toggleSettingsDialog = (newOpen: boolean) => {
    setSettingsOpen(newOpen);
  };

  const handleRefreshClick = () => {
    refreshOrders && refreshOrders();
    setRefreshDisabled(true);
    setTimeout(() => setRefreshDisabled(false), 5000); // Disable button for 5 seconds
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ bgcolor: 'white', paddingLeft: backButton ? 1 : 2 }}>
        {backButton && (
          <SecondaryButton
            text="buttons.back"
            action={() => navigate(-1)}
            style={{ width: '80px', marginRight: '8px' }}
          />
        )}

        <Typography
          color="black"
          textAlign="center"
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {pageTitle}
        </Typography>

        {showOrderFunctions && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.25 }}>
            <IconButton
              size="large"
              aria-label="filter"
              sx={{
                color: 'black',
                border: 0,
                padding: 0,
                borderRadius: 0,
                width: '40px',
                height: '40px',
              }}
              onClick={() => toggleDrawer(true)}
            >
              <FilterListIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="settings"
              sx={{
                color: 'black',
                border: 0,
                padding: 0,
                borderRadius: 0,
                width: '40px',
                height: '40px',
              }}
              onClick={() => toggleSettingsDialog(true)}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        )}

        {showOrderFunctions && (
          <IconButton
            size="large"
            aria-label="refresh"
            sx={{
              color: refreshDisabled ? 'grey' : 'black',
              marginLeft: 1,
              border: 0,
              padding: 0,
              borderRadius: 0,
              width: '40px',
              height: '40px',
            }}
            onClick={handleRefreshClick}
            disabled={refreshDisabled}
          >
            <RefreshIcon />
          </IconButton>
        )}

        {!showProfile && (
          <LanguageSelector small i18n={i18n} />
        )}

        {showProfileFlag && (
          <IconButton
            edge="end"
            onClick={() => navigate('/profile')}
            sx={{
              color: '#172339',
              mx: 0.25,
              '&:hover': {
                backgroundColor: '#172339',
                color: '#faf8f6',
              },
            }}
          >
            <AccountCircle />
          </IconButton>
        )}
      </Toolbar>
      <Drawer open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <OrderFilterDrawer toggleDrawer={toggleDrawer} />
      </Drawer>
      <Dialog open={settingsOpen} onClose={() => toggleSettingsDialog(false)}>
        <DialogContent>
          <OrderSettingsDialog open={settingsOpen} onClose={() => toggleSettingsDialog(false)} />
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};
