import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography, Divider, Box } from "@mui/material";
import { Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { Draggable, Droppable, DragDropContext, DropResult } from "@hello-pangea/dnd";
import { useState } from "react";
import { DeleteConfirmDialog } from "./dialogs/DeleteDialog";

interface DraggableListProps<T> {
    items: T[];
    title: string;
    onItemSelect: (item: T) => void;
    onItemDelete: (item: T) => void;
    onAddItem: () => void;
    onDragEnd: (updatedItems: T[]) => void;
}

export const DraggableList = <T extends { id: string, name: string }>({
    items,
    title,
    onItemSelect,
    onItemDelete,
    onAddItem,
    onDragEnd,
}: DraggableListProps<T>) => {
    const [selectedItem, setSelectedItem] = useState<T | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<T | null>(null);

    const handleSelect = (item: T) => {
        setSelectedItem(item);
        onItemSelect(item);
    };

    const handleDelete = (item: T) => {
        setItemToDelete(item);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = () => {
        if (itemToDelete) {
            onItemDelete(itemToDelete);
            setItemToDelete(null);
            setDeleteDialogOpen(false);
        }
    };

    const handleDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        if (!destination) return;

        const updatedItems = Array.from(items);
        const [movedItem] = updatedItems.splice(source.index, 1);
        updatedItems.splice(destination.index, 0, movedItem);
        onDragEnd(updatedItems);
    };

    return (
        <Box sx={{ border: 1, borderColor: 'grey.300', p: 2, borderRadius: 1 }}>
            <Typography variant="h6" display="flex" justifyContent="space-between" alignItems="center">
                {title}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={title}>
                    {(provided) => (
                        <List ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided) => (
                                        <ListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() => handleSelect(item)}
                                            sx={{
                                                backgroundColor: selectedItem?.id === item.id ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
                                                '&:hover': { backgroundColor: 'rgba(100, 100, 100, 0.1)' },
                                            }}
                                        >
                                            <ListItemText primary={item.name} />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(item);
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
            <IconButton onClick={onAddItem} style={{ width: '40px' }}>
                <AddIcon />
            </IconButton>
            <DeleteConfirmDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={confirmDelete}
            />
        </Box>
    );
};
