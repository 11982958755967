import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppContext } from '../AppProvider';
import { Product, Size, IDUtil, ProductExtra, ProductFilter, ProductCategory, I18nUtil } from 'base.f6st.com';
import { SecondaryButton, StandardButton } from 'common.f6st.com';

type SizesDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (product: Product) => void;
    product: Product;
};

export const SizesDialog: React.FC<SizesDialogProps> = ({ open, onClose, onSave, product }) => {
    const customer = useAppContext().getCustomer();
    const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
    const [prices, setPrices] = useState<Size[]>(product.sizes || []);

    useEffect(() => {
        if (open) {
            setPrices(product.sizes || []);
        }
    }, [open, product]);

    const handleAdd = () => {
        const newId = IDUtil.getShortId();
        const inputElement = document.getElementById(`size-name`) as HTMLInputElement;
        const newSize = inputElement ? inputElement.value : '';
        const priceElement = document.getElementById('size-price') as HTMLInputElement;
        const newSizePrice = priceElement ? parseFloat(priceElement.value) : 0;
        const newPriceEntry: Size = { id: newId, price: newSizePrice, nameId: newSize };
        const updatedPrices = [...prices, newPriceEntry];
        setPrices(updatedPrices);
        onSave({ ...product, sizes: updatedPrices });
        onClose(); // Close dialog after saving
    };

    const handleClose = () => {
        setPrices(product.sizes || []);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="h6" component="div">Add Sizes</Typography>
                <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    id={`size-name`}
                    label="Size"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    id="size-price"
                    label={`Price (${currency})`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="number"
                />
            </DialogContent>
            <DialogActions>
                <SecondaryButton action={handleClose} text="Cancel" />
                <StandardButton action={handleAdd} text="Add" />
            </DialogActions>
        </Dialog>
    );
};

type AddExtraDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (product: Product) => void;
    product: Product;
};

export const AddExtraDialog: React.FC<AddExtraDialogProps> = ({ open, onClose, onSave, product }) => {
    const customer = useAppContext().getCustomer();
    const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
    const [extras, setExtras] = useState<ProductExtra[]>(product.extras);

    useEffect(() => {
        if (open) {
            setExtras(product.extras);
        }
    }, [open, product]);

    const handleAdd = () => {
        const newId = IDUtil.getShortId();
        const inputElement = document.getElementById(`extra-name`) as HTMLInputElement;
        const newExtraName = inputElement ? inputElement.value : '';
        const priceElement = document.getElementById('extra-price') as HTMLInputElement;
        const newExtraPrice = priceElement ? parseFloat(priceElement.value) : 0;
        const newExtraEntry: ProductExtra = { id: newId, nameId: newExtraName, price: newExtraPrice };
        const updatedExtras = [...extras, newExtraEntry];
        setExtras(updatedExtras);
        onSave({ ...product, extras: updatedExtras });
        onClose(); // Close dialog after adding
    };

    const handleClose = () => {
        setExtras(product.extras);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="h6" component="div">Add Extra</Typography>
                <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    id={`extra-name`}
                    label="Extra Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    id="extra-price"
                    label={`Price (${currency})`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="number"
                />
            </DialogContent>
            <DialogActions>
                <SecondaryButton action={handleClose} text="Cancel" />
                <StandardButton action={handleAdd} text="Add" />
            </DialogActions>
        </Dialog>
    );
};

type ManageFiltersDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (product: Product) => void;
    product: Product;
};

export const ManageFiltersDialog: React.FC<ManageFiltersDialogProps> = ({
    open = false,
    onClose = () => { },
    onSave = () => { },
    product = {} as Product,
}) => {
    const customer = useAppContext().getCustomer();
    const [showManage, setShowManage] = useState(false);
    const [productFilters, setProductFilters] = useState<ProductFilter[]>([]);
    const [allFilters, setAllFilters] = useState<ProductFilter[]>([]);
    const products = customer.products;

    useEffect(() => {
        if (open && product.filterIds) {
            const currentFilters = product.filterIds.map(id => products.filters.find((filter: ProductFilter) => filter.id === id) || { id, nameId: "" });
            setProductFilters(currentFilters);
            setAllFilters(JSON.parse(JSON.stringify(products.filters)));
        }
    }, [open, product, products.filters]);

    const handleAdd = () => {
        const newId = IDUtil.getShortId();
        const inputElement = document.getElementById(`filter-name`) as HTMLInputElement;
        const newFilterName = inputElement ? inputElement.value : '';
        const newFilterEntry: ProductFilter = { id: newId, nameId: newFilterName };
        const updatedFilters = [...productFilters, newFilterEntry];

        if (customer && products.filters) {
            products.filters.push(newFilterEntry);
        }

        onSave({ ...product, filterIds: updatedFilters.map((filter: ProductFilter) => filter.id) });
        onClose();
    };

    const handleDelete = (id: string) => {
        const updatedFilters = allFilters.filter((filter: ProductFilter) => filter.id !== id);

        // remove the filter from the customer
        products.filters.splice(products.filters.findIndex((filter: ProductFilter) => filter.id === id), 1);

        // remove all references to the filter in all products
        products.categories.forEach((category: ProductCategory) => {
            category.products.forEach((product: Product) => {
                if (product.filterIds)
                    product.filterIds = product.filterIds.filter((filterId: string) => filterId !== id);
            });
        })
        setAllFilters(updatedFilters);
    };

    const handleSave = () => {
        const updatedFilters = allFilters.map((filter: ProductFilter) => {
            const inputElement = document.getElementById(`filter-name-${filter.id}`) as HTMLInputElement;
            const updatedName = inputElement ? inputElement.value : '';
            return { ...filter, nameId: updatedName };
        });

        if (customer) {
            products.filters = [...updatedFilters];
            setAllFilters(products.filters);
        }
        setShowManage(false);
        onClose();
    };

    const handleClose = () => {
        setShowManage(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl">
            <DialogTitle>
                <Typography variant="h6" component="div">{showManage ? "Manage Filters" : "Add Filter"}</Typography>
                <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {!showManage && (
                <>
                    <DialogContent>
                        <TextField
                            id={`filter-name`}
                            label="Filter Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton action={handleClose} text="Cancel" />
                        <SecondaryButton action={() => setShowManage(true)} text="All Filters" />
                        <StandardButton action={handleAdd} text="Add" />
                    </DialogActions>
                </>
            )}
            {showManage && (
                <>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Filter Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allFilters.map((filter: ProductFilter) => (
                                    <TableRow key={`row-${filter.id}`}>
                                        <TableCell>
                                            <TextField
                                                id={`filter-name-${filter.id}`}
                                                fullWidth
                                                variant="outlined"
                                                defaultValue={filter.nameId}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleDelete(filter.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton action={handleClose} text="Cancel" />
                        <StandardButton action={handleSave} text="Save" />
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
